
import { defineComponent } from "vue-demi";
import Transaction from "@/components/transaction/List.vue";

export default defineComponent({
  name: "TransactionView",
  components: {
    Transaction,
  },
});
